import React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Link } from "@mui/material";
import bellIcon from "../../../src/Assets/images/Group 1171276529.svg";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import CloseIcon from "@mui/icons-material/Close";
import { LocalStorageSetValue } from "../../Services/Auth";
import LinearProgress from "@mui/material/LinearProgress";
import LongText from "./longText";
import "./notificationCss.scss";
import { getConsultDetail as getPatientDetail } from "../../Services/ConsultsService";

const useStyles = makeStyles({
  notifyParentDiv: {
    display: "flex",
    columnGap: 20,
    minHeight: 53,
    alignItems: "center",
    marginLeft: 16,
    marginRight: 16,
    paddingTop: 8,
  },
  notifycontentDiv: {
    display: "flex",
    columnGap: 5,
    minHeight: 25,
    alignItems: "center",
  },
  notifyChildDiv: {
    flexBasis: "226px",
    display: "flex",
    flexDirection: "column",
    minHeight: "30px",
    fontSize: "12px",
    justifyContent: "space-between",
  },
  notifyButton: {
    color: "#9B51E0 !important",
    borderColor: "#9B51E0 !important",
    width: "25px !important",
    height: "24px !important",
    textTransform: "none !important",
    borderRadius: "8px !important",
    whiteSpace: "nowrap !important",
  },
  notifyPaper: {
    borderRadius: "8px !important",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.25)",
    width: 393,
    marginTop: -16,
  },
  notifyPaperDiv: {
    paddingTop: 10,
    paddingLeft: 10,
  },
  notifyDividerNotification: {
    display: "block !important",
    height: "1px !important",
    border: "0 !important",
    borderTop: "1px solid #EBECF0 !important",
    padding: "0 !important",
    width: "90%",
    margin: "auto !important",
  },

  notifyDividerNotificationTop: {
    display: "block !important",
    height: "1px !important",
    border: "0 !important",
    borderTop: "1px solid #EBECF0 !important",
    padding: "0 !important",
  },

  notifyFinalContent: {
    textAlign: "center",
    paddingTop: 10,
    paddingBottom: 10,
  },
  notificationNoRecords: {
    textAlign: "center",
    paddingBottom: 50,
    fontSize: "18px",
    fontWeight: "500",
    fontFamily: "SF Pro Text",
    color: "#344563",
  },
  imageSize: {
    width: 34,
    height: 34,
  },
  popupContent: {
    overflow: "auto",
    maxHeight: 432.97,
  },
  notificationCount: {
    fontSize: "xx-small",
    color: "white",
    position: "relative",
    fontWeight: 600,
  },
  popupnotificationdot: {
    width: "16px",
    height: "16px",
    background: "#E52727",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "12px",
    border: "1px solid #F8F8F8",
    marginTop: "-16px",
    marginLeft: "93px",
  },
  arrowup: {
    width: 0,
    height: 0,
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    borderBottom: "10px solid black",
  },
  subText: {
    fontWeight: 600,
  },
});

export default function NotificationBar({
  props,
  setAnchorEl,
  setnotifyStatus,
  data,
  loader,
  serverTime,
}) {
  const classes = useStyles();
  const handleClickAway = () => {
    setAnchorEl(null);
    setnotifyStatus("open");
  };

  function getTimeDiff(createdtimevalue) {
    let date1, date2;
    let time = "";
    date1 = new Date(serverTime);
    date2 = new Date(createdtimevalue);
    let res = Math.abs(date1 - date2) / 1000;
    let days = Math.floor(res / 86400);
    let hours = Math.floor(res / 3600) % 24;
    let minutes = Math.floor(res / 60) % 60;
    let seconds = Math.floor(res) % 60;
    if (days > 0) time += days + "d ";
    if (hours > 0) time += hours + "h ";
    if (minutes > 0) time += minutes + "m ";
    if (seconds > 0) time += seconds + "s ago";
    return time;
  }

  const callNotificationDetailsPage = () => {
    setAnchorEl(null);
    setnotifyStatus("open");
    props.history.push(`/notificationDetails`);
    window.history.replaceState(null, "", "/");
    sessionStorage.setItem("currentPath", "/notificationDetails");
  };

  const navigateToDashboard = async (val) => {
    setAnchorEl(null);
    setnotifyStatus("open");
    if (
      val.messageType === "new_message" ||
      val.messageType === "new_attachment" ||
      val.messageType === "Acuity Change Alert" ||
      val.messageType === "Patient Vitals Change Alert"
    ) {
      const {
        patient: { status: patientStatus },
      } = await getPatientDetail(val.patientId);
      LocalStorageSetValue("patientId", val.patientId);
      LocalStorageSetValue("consultId", val.consultId);
      LocalStorageSetValue("landingPageStatus", patientStatus);
      props.history.push(`/econsults`, {
        landingPageStatus: patientStatus,
      });
      window.history.replaceState(null, "", "/");
      sessionStorage.setItem("currentPath", "/econsults");
    } else if (val.messageType === "New Hospital Enabled") {
      props.history.push(`/patientCensus`);
      sessionStorage.setItem("currentPath", "/patientCensus");
      window.history.replaceState(null, "", "/");
    } else if (val.messageType === "Password Expiry") {
      LocalStorageSetValue("changePasswordExapand", true);
      props.history.push("/settings");
      window.history.replaceState(null, "", "/");
      sessionStorage.setItem("currentPath", "/settings");
    } else if (val.messageType === "Session Expiry Warning") {
      setAnchorEl(null);
      setnotifyStatus("open");
    } else if (val.messageType === "System Alert") {
      props.history.push(`/notificationDetails`);
      window.history.replaceState(null, "", "/");
      sessionStorage.setItem("currentPath", "/notificationDetails");
    } else if (val.messageType === "ZoomConference") {
      props.history.push(`/conference`, { reload: true });
      window.history.replaceState(null, "", "/");
      sessionStorage.setItem("currentPath", "/conference");
    } else if (val.messageType === "Patient Assigned") {
      props.history.push(`/notificationDetails`);
      window.history.replaceState(null, "", "/");
      sessionStorage.setItem("currentPath", "/notificationDetails");
    } else {
      props.history.push(`/dashboard`);
      window.history.replaceState(null, "", "/");
      sessionStorage.setItem("currentPath", "/dashboard");
    }
  };
  const individualrowitem = data.map((item, index) =>
    index < 6 ? (
      <div className="setBackgroundColor">
        <div className={classes.notifyParentDiv}>
          <Avatar
            alt="Omnicure"
            sx={{ width: 34, height: 34 }}
            src={item.profilePicUrl}
          />
          <div
            className={classes.notifyChildDiv}
            style={{ position: "relative" }}
          >
            <div className={classes.notifycontentDiv}>
              <LongText
                data={item}
                classes={classes}
                providerNameHighlight={"black"}
              />
            </div>
            <div>
              <Typography className="notification-timestamp-text">
                {getTimeDiff(item.createdTime)}{" "}
              </Typography>
            </div>
          </div>
          <Button
            className={classes.notifyButton}
            variant="outlined"
            onClick={() => navigateToDashboard(item)}
          >
            <Typography className="notification-btn-text">View</Typography>
          </Button>
        </div>
        <Divider className={classes.notifyDividerNotification} />
      </div>
    ) : (
      ""
    )
  );

  return (
    <div>
      {/* <div id="arrowDiv" className={classes.arrowup}>
      </div> */}
      <ClickAwayListener onClickAway={handleClickAway}>
        <Paper className={classes.notifyPaper}>
          <div className={classes.notifyPaperDiv}>
            <div style={{ float: "left" }}>
              <Typography className="profile-header">
                {"NOTIFICATIONS"}
              </Typography>
            </div>
            <div>
              <CloseIcon
                fontSize="small"
                style={{
                  marginLeft: 265,
                  color: "#8993A4",
                  width: 15,
                  cursor: "pointer",
                }}
                onClick={handleClickAway}
              ></CloseIcon>
            </div>
          </div>
          <Divider className={classes.notifyDividerNotificationTop} />
          {!loader ? (
            <div>
              {data.length > 0 ? (
                <div className={classes.popupContent}>
                  <div>{individualrowitem}</div>
                  <div className={classes.notifyFinalContent}>
                    <Typography className="profile-footer">
                      <Link
                        color="#172B4D"
                        onClick={callNotificationDetailsPage}
                        style={{ cursor: "pointer" }}
                      >
                        {"View All"}
                      </Link>
                    </Typography>
                  </div>
                </div>
              ) : (
                <div className={classes.notifyPaperChidDiv}>
                  <div style={{ marginLeft: 135 }}>
                    <Avatar
                      alt="Omnicure"
                      sx={{ width: 72.38, height: 72.38 }}
                      src={bellIcon}
                    />
                  </div>
                  <div>
                    <Typography className={classes.notificationNoRecords}>
                      No notifications yet
                    </Typography>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div>
              <LinearProgress color="secondary" />
            </div>
          )}
        </Paper>
      </ClickAwayListener>
    </div>
  );
}
